* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: 0;
  font-family: 'Mulish', sans-serif;
}

body {
  font-family: 'Mulish', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: intialiased;
}

button {
  cursor: pointer;
}

code {
  font-family: 'Mulish', sans-serif;
}
